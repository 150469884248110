import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';

export const withInvalidAppState = (WrappedComponent, Fallback) => {
  Fallback = Fallback || (() => <div />);
  class WithInvalidAppState extends React.Component {
    static displayName = `withInvalidAppState(${getDisplayName(WrappedComponent)})`;

    render() {
      const { workerError } = this.props;

      if (workerError && workerError.isSSR === true && this.props.isSSR !== false) {
        // worker error happened in SSR and this is SSR render we want to show nothing, because it might succeed on CSR
        return <Loader />;
      }

      if (
        (workerError && workerError.isSSR === false) || // worker error on CSR
        (!workerError &&
          !(this.props.basicParams || this.props.isLoading) &&
          !this.props.provisioningInProgress) // no worker error, bu no state (basicParams), so we have to fail
      ) {
        if (workerError && workerError.wasWorkerErrorCaptured) {
          return <Fallback {...this.props} />;
        } else {
          throw new Error(
            `Worker initialization failed: ${
              workerError ? workerError.error : 'There is no error, props were not set'
            }`,
          ); // this error will be caught by sentry error boundary
        }
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithInvalidAppState, WrappedComponent);

  return WithInvalidAppState;
};
