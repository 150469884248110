import { flowRight } from 'lodash';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withAuth from '../../hoc/with-auth';
import ActionButton from '../action-button';
import NotificationsLink from '../link/notifications-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { NotificationIcon } from '../icons/notification-icon';
import CurrentUserAvatarImage from '../../containers/current-user-avatar-image';
import { LogoutIcon } from '../icons/logout-icon';
import AvatarImage from '../avatar-image';
import { LoginIcon } from '../icons/login-icon';

class ProfileActionsAddon extends Component {
  handleGoToProfile = () => {
    const { buttonClicked, navigateToProfile, currentUser } = this.props;
    buttonClicked({ action: 'profile' });
    navigateToProfile({ memberId: currentUser.siteMemberId, memberSlug: currentUser.slug });
  };

  handleLogout = () => {
    const { buttonClicked, navigateToRoot, logoutMember } = this.props;
    navigateToRoot();
    buttonClicked({ action: 'logout' });
    logoutMember();
  };

  handleLogin = () => {
    const { buttonClicked, loginMember } = this.props;
    buttonClicked({ action: 'login' });
    loginMember();
  };

  render() {
    const { isAuthenticated, t } = this.props;

    const profileButton = isAuthenticated ? (
      <ActionButton data-hook="private-profile-actions__profile" onClick={this.handleGoToProfile}>
        <CurrentUserAvatarImage type={AvatarImage.SMALL} />
        {t('breadcrumbs.profile')}
      </ActionButton>
    ) : null;
    const notificationsButton = isAuthenticated ? (
      <ActionButton dataHook="private-profile-actions__notifications">
        <NotificationIcon />
        <NotificationsLink class="forum-text-color">Notifications</NotificationsLink>
      </ActionButton>
    ) : null;
    const logoutButton = isAuthenticated ? (
      <ActionButton data-hook="private-profile-actions__logout" onClick={this.handleLogout}>
        <LogoutIcon />
        {t('private-profile-actions.logout')}
      </ActionButton>
    ) : null;
    const loginButton = !isAuthenticated ? (
      <ActionButton data-hook="private-profile-actions__logout" onClick={this.handleLogin}>
        <LoginIcon />
        {t('private-profile-actions.login')}
      </ActionButton>
    ) : null;

    return (
      <React.Fragment>
        {profileButton}
        {notificationsButton}
        {logoutButton}
        {loginButton}
      </React.Fragment>
    );
  }
}

ProfileActionsAddon.propTypes = {
  isAuthenticated: PropTypes.bool,
  navigateToRoot: PropTypes.func,
  navigateToProfile: PropTypes.func,
  loginMember: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    logoutMember: actions.logout,
    loginMember: actions.requestLogin,
    navigateToRoot: actions.navigateToRoot,
    navigateToProfile: actions.navigateToProfile,
    buttonClicked: actions.buttonClicked,
  };
};

export default flowRight(connect(mapRuntimeToProps), withAuth, withTranslate)(ProfileActionsAddon);
