import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import Avatar from '../avatar';
import MoreButton from '../more-button';
import { PinIcon } from '../icons/pin-icon';
import { LockIcon } from '../icons/lock-icon';
import PostActions from '../../containers/post-actions';
import TimeAgo from '../time-ago';
import BestAnswerIconLink from '../best-answer-icon-link';
import styles from './post-header.scss';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { BulletSeparator } from '../separator';
import { MODAL_TYPE_POST_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import MoreButtonMobile from '../more-button-mobile';
import withDeviceType from '../../hoc/with-device-type';
import NewContentIndicator from '../new-content-indicator';

const ICON_ANIMATION_TIMEOUT = 200;

const renderPinIcon = (t, isPinned) => {
  if (!isPinned) {
    return null;
  }

  return (
    <div className={styles.iconWrapper} key="pin">
      <div className={classNames(styles.icon, 'pin-icon')} data-hook="pin-icon">
        <PinIcon
          className="icon-secondary-fill"
          isPinned={isPinned}
          aria-label={t('post-list-item.pinned-post')}
        />
      </div>
    </div>
  );
};

const renderLockIcon = (isCommentsDisabled, type) => {
  if (!isCommentsDisabled || type === PostHeader.POST_PAGE) {
    return null;
  }

  return (
    <div className={styles.iconWrapper} key="lock">
      <div className={classNames(styles.icon, 'lock-icon')} data-hook="lock-icon">
        <LockIcon className="icon-secondary-fill" isLocked={isCommentsDisabled} />
      </div>
    </div>
  );
};

const renderBestAnswerIcon = (t, post) => {
  return (
    <div className={styles.iconWrapper} key="best-answer">
      <div className={styles.icon} data-hook="best-answer-icon">
        <BestAnswerIconLink t={t} commentId={post.bestAnswerCommentId} post={post} />
      </div>
    </div>
  );
};

const renderMoreButton = (moreButtonId, post, origin, isMobile) => {
  return (
    <div className={styles.moreButton}>
      {isMobile ? (
        <MoreButtonMobile
          type={MODAL_TYPE_POST_ACTIONS}
          post={post}
          focusOnCloseId={moreButtonId}
          origin={origin}
        />
      ) : (
        <MoreButton id={moreButtonId}>
          <PostActions post={post} focusOnCloseId={moreButtonId} origin={origin} />
        </MoreButton>
      )}
    </div>
  );
};

const avatarTypeMap = {
  search: Avatar.MEDIUM,
  medium: Avatar.MEDIUM,
  large: Avatar.LARGE,
  footerPost: Avatar.FOOTER_POST,
  postPage: Avatar.POST_PAGE,
  recentPostsWidget: Avatar.RECENT_POSTS_WIDGET,
  postPageNew: Avatar.POST_PAGE_HEADER,
};

function PostHeader({
  type,
  post,
  showBestAnswer,
  showLastActivityDate,
  showDateBelowAvatar,
  showAvatarBadges,
  showPinIcon,
  showLockIcon,
  showMoreButton,
  showEditedDate,
  showMemberCard,
  showNewPostIndicator,
  truncateBadgeTitle,
  t,
  origin,
  showUserInfoInline,
  isMobile,
}) {
  const owner = post.owner || {};
  const className = classNames(styles.container, styles[type], 'post-header');
  const avatarType = avatarTypeMap[type];
  const moreButtonId = `more-button-${post._id}`;
  const shouldShowBestAnswer = showBestAnswer && Boolean(post.bestAnswerCommentId);
  const date = showLastActivityDate ? post.lastActivityDate : post.createdDate;
  const shouldShowDateBelowAvatar = !isMobile && showDateBelowAvatar;

  return (
    <div className={className}>
      <Avatar
        type={avatarType}
        user={owner}
        date={date}
        showDate={shouldShowDateBelowAvatar}
        showBadges={showAvatarBadges}
        truncateBadgeTitle={truncateBadgeTitle}
        showMemberCard={showMemberCard}
        showUserInfoInline={showUserInfoInline}
      />
      <div className={styles.status}>
        <div className={styles.date}>
          {(type === PostHeader.POST_PAGE || type === PostHeader.POST_PAGE_NEW) && (
            <div key="timeago">{!isMobile && !showDateBelowAvatar && <TimeAgo time={date} />}</div>
          )}
          {!isMobile && showEditedDate && post.editedDate && (
            <span>
              {!shouldShowDateBelowAvatar && <BulletSeparator />}
              <span data-hook="edited-post-label">{t('post-page.edited')}&nbsp;</span>
              <TimeAgo time={post.editedDate} />
            </span>
          )}
        </div>
        <CSSTransitionGroup
          className={styles.icons}
          transitionName="post-header-icons"
          transitionEnterTimeout={ICON_ANIMATION_TIMEOUT}
          transitionLeaveTimeout={ICON_ANIMATION_TIMEOUT}
        >
          {showNewPostIndicator && (
            <NewContentIndicator
              hideBackground
              hideNumber
              hideWord
              noSpacing
              count={post.isSeen === false && 1}
            />
          )}
          {showPinIcon && renderPinIcon(t, post.isPinned)}
          {showLockIcon && !isMobile && renderLockIcon(post.isCommentsDisabled, type)}
          {shouldShowBestAnswer && renderBestAnswerIcon(t, post)}
        </CSSTransitionGroup>
        {showMoreButton && renderMoreButton(moreButtonId, post, origin, isMobile)}
      </div>
    </div>
  );
}

PostHeader.FOOTER_POST = 'footerPost';
PostHeader.POST_PAGE = 'postPage';
PostHeader.POST_PAGE_NEW = 'postPageNew';
PostHeader.RECENT_POSTS_WIDGET = 'recentPostsWidget';

PostHeader.propTypes = {
  type: PropTypes.string,
  post: PropTypes.object.isRequired,
  showPinIcon: PropTypes.bool,
  showLockIcon: PropTypes.bool,
  showBestAnswer: PropTypes.bool,
  showLastActivityDate: PropTypes.bool,
  showDateBelowAvatar: PropTypes.bool,
  showAvatarBadges: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showEditedDate: PropTypes.bool,
  showMemberCard: PropTypes.bool,
  showNewPostIndicator: PropTypes.bool,
  t: PropTypes.func,
  origin: PropTypes.string.isRequired,
  showUserInfoInline: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

PostHeader.defaultProps = {
  showAvatarBadges: true,
  showPinIcon: true,
  showLockIcon: true,
  showMoreButton: true,
  showEditedDate: false,
  showBestAnswer: false,
  showUserInfoInline: false,
};

export default flowRight(withTranslate, withDeviceType)(PostHeader);
