import { memoize } from 'lodash';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import RecentPostsWidgetRoot from '../../../recent-posts-widget/components/recent-posts-widget-root';
import { withLayoutUpdate } from '../../../common/hoc/with-layout-update';
import { withInvalidAppState } from '../../../common/hoc/with-invalid-app-state';
import InvalidAppStatePage from '../../../app/components/invalid-app-state-page';
import { withErrorState } from '../../../common/hoc/with-error-state';

const withStylesConfig = {
  cssPath: ['recent-posts-widget-viewer.bundle.css', 'recent-posts-widget-viewer.css'],
  enableMemoization: memoize.Cache !== WeakMap,
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default withErrorState(
  withLayoutUpdate(
    withInvalidAppState(withStyles(RecentPostsWidgetRoot, withStylesConfig), FallbackPage),
  ),
  FallbackPage,
);
